<template>
  <b-card>
    <b-row>

      <!-- Button to add communication -->
      <b-col v-if="role !== 'supervisor' && isOnline" sm="8" class="pb-1">
        <b-button
          variant="outline-primary"
          :to="{ name: 'habit-meeting-communications-add' }"
        >
          <feather-icon
            icon="PlusIcon"
            class="mr-50"
          />
          {{ $t('message.Add_Communication') }}
        </b-button>
      </b-col>

      <!-- Search communication -->
      <b-col v-if="isOnline" sm="4">
        <b-form-group>
          <b-form-input
            :placeholder="$t('message.search')"
            type="search"
            class="d-inline-block"
            v-model="searchQuery"
            @input="searchCommunication"
          />
        </b-form-group>
      </b-col>

      <!-- List communications -->
      <b-col cols="12">
        <b-table
          :empty-text="isLoading ? $t('message.loading') : $t('message.no_records')"
          show-empty=""
          :items="paginatedCommunications"
          hover
          responsive
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="sortBy"
          :sort-desc.sync="isSortDirDesc"
        >
          <!-- <template #cell(location)="data">
            <ul>
              <li
                v-for="location in data.item.locations"
                :key="location.location"
              > {{ location.location }}
              </li>
            </ul>
          </template> -->

          <template #cell(location)="data">
            <ul>
              <li v-for="(location, index) in data.item.locations.slice(0, 3)" :key="location.location">
                {{ location.location }}
              </li>
              <li v-if="data.item.locations.length > 3">
                {{ $t('others') }} ...
              </li>
            </ul>
          </template>

          <template #cell(detail)="data">
            <div v-html="data.item.detail.length > 20 ? data.item.detail.substring(0, 20) + '...' : data.item.detail"></div>
          </template>

          <template #cell(imageKey)="data">
            <b-button v-if="data.item.imageKey"
              size="sm"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              type="button"
              variant="primary"
              @click="openImage(data.item.imageKey)"
            > {{ $t('message.open') }}
            </b-button>
            <small v-else> {{ $t('happiness.no_image') }} </small>
          </template>

          <template #cell(actions)="data">
            <div class="flex flex-column">
              <ViewButton variant="flat-success" @clicked="router.push({ name: 'habit-meeting-communications-view', params: { id:  data.item._id} })"/>
              <EditButton @clicked="router.push({ name: 'habit-meeting-communications-edit', params: { id:  data.item._id} })"/>
              <DeleteButton v-b-modal="`modal ${data.item._id}`"/>
              <b-modal
                :id="`modal ${data.item._id}`"
                centered
                no-close-on-backdrop
                modal-class="modal-danger"
                ok-variant="danger"
                cancel-variant="outline-secondary"
                :title="$t('message.confirm_action')"
                :ok-title="$t('message.delete')"
                :cancel-title="$t('message.cancel')"
                @ok="deleteCommunication(data.item._id)"
              > {{ $t('message.confirm_delete_communication') }}
              </b-modal>
            </div>
          </template>
        </b-table>
      </b-col>

      <!-- Pagination -->
      <b-col
        v-if="isOnline && communications.length > 0"
        cols="12"
        class="d-flex justify-content-between flex-wrap"
      >
        <div class="mb-1">
          <b-form-group class="mb-0" >
            <label class="d-inline-block text-sm-left mr-50">{{ $t('message.per_page') }}</label>
            <b-form-select
              id="perPageSelect"
              v-model="perPage"
              size="sm"
              :options="[5,10,15,20,25,30,40,50,100]"
              class="w-50"
            />
          </b-form-group>
        </div>

        <div class="mb-1">
          <span class="text-muted">{{ showingMessage }}</span>
        </div>

        <b-pagination
          v-model="currentPage"
          :total-rows="totalCommunications"
          :per-page="perPage"
          first-number=""
          last-number=""
          prev-class="prev-item"
          next-class="next-item"
        />
      </b-col>

    </b-row>

    <!-- Download xlsx -->
    <b-button v-if="!isLoading" class="mr-4 my-1" :disabled="!paginatedCommunications.length">
      <json-excel :data="dataExcel" :fields="communicationsFields">
        {{ $t('download_xlsx') }}
      </json-excel>
    </b-button>
  </b-card>
</template>
    
<script>
import { BPagination, BTable, BModal, VBModal } from 'bootstrap-vue'
import useCommunications from './useCommunications'
import ViewButton from '@/views/components/Shared/Buttons/ViewButton.vue'
import EditButton from '@/views/components/Shared/Buttons/EditButton.vue'
import DeleteButton from '@/views/components/Shared/Buttons/DeleteButton.vue'
import Ripple from 'vue-ripple-directive'
import { onMounted, computed } from '@vue/composition-api'
import JsonExcel from "vue-json-excel"

export default {
  components: {
    BTable,
    BPagination,
    BModal,
    ViewButton,
    EditButton,
    DeleteButton,
    JsonExcel
  },
  directives: {
    Ripple,
    'b-modal': VBModal,
  },
  setup() {
    const {
      role,
      isOnline,
      searchQuery,
      communications,
      tableColumns,
      sortBy,
      isSortDirDesc,
      isLoading,
      perPage,
      showingMessage,
      totalComminications,
      currentPage,
      openImage,
      router,
      deleteCommunication,
      listCommunications,
      searchCommunication,
      totalCommunications,
      communicationsFields,
      dataExcel
    } = useCommunications()

    onMounted(() => {
        listCommunications()
    })

    const paginatedCommunications = computed(() => {
      const start = (currentPage.value - 1) * perPage.value
      const end = start + perPage.value
      return communications.value.slice(start, end)
    })

    return {
      role,
      isOnline,
      searchQuery,
      communications,
      tableColumns,
      sortBy,
      isSortDirDesc,
      isLoading,
      perPage,
      showingMessage,
      totalComminications,
      currentPage,
      openImage,
      router,
      deleteCommunication,
      searchCommunication,
      totalCommunications,
      paginatedCommunications,
      communicationsFields,
      dataExcel
    }
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-select.scss";
</style>